// import request from '@/utils/request'

import request from '@/axios'

export async function logout() {
  const res = await request.post('/logout')
  return res?.data
}


// @Summary 用户登录
// @Produce  application/json
// @Param data body {account_name:"string",password:"string"}
// @Router /base/login [post]
export async function login(data: any) {
  const res = await request.post('/login', data)
  return res?.data
}

// 获取内容列表筛选条件
export async function getFilterList() {
  const res = await request.get('/admin/dropdown/needlist')
  return res?.data
}

// const pending: any = {
//   token: ''
// }
/**
 * 获取token
 */
// export const getToken = () => {
//   if (pending.token) return pending.token
//   pending.token = request.get('/home', { responseType: 'text' }).then(({ data }:any) => {
//     delete pending.token
//     if (/name="?token"?\s*content="?([^>\s"]+)"?/.test(data)) return RegExp.$1
//     return ''
//   }).catch(() => {
//     delete pending.token
//   })
//   return pending.token
// }
export const getToken = async () => {
  const res = await request.get('/token')
  return res?.data
}