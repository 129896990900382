import request from '@/axios'

interface dockData {
  [propName: string]: any
}

// 对接数据设置
export async function dockDataPost(data: dockData) {
  const res = await request.post('/admin/demand/dockingData', data)
  return res?.data
}

// 获取内容列表
export async function requestDemand(data: any) {
  const res = await request.post('/admin/demand/requestDemand', data)
  return res?.data
}

export default {
  dockDataPost,
  requestDemand
}